<template>
  <div
    :data-ref="$options.consts.REFS.HEADER_MOBILE_EXPANDED_MENU"
    class="header-mobile-expanded__menu-container"
    :style="wrapperStyles"
  >
    <a-global-quicksearch
      class="header-mobile-expanded__search-bar-container"
      v-on="$listeners"
    />

    <button @click="openNewsLetterModal" class="single-social-btn top">
      <a-icon
        :icon="getOurNewsletter.icon"
        :width="15"
        :height="15"
        fill="#fff"
        class="social-icon"
      />
      <p class="social-title">
        {{ getOurNewsletter.name }}
      </p>
    </button>

    <a-header-menu-block
      :menu-items="menuItemsForSectionOne"
      @onCloseMenu="closeMenu"
    />

    <a-header-menu-block
      :menu-items="menuItemsForSectionTwo"
      @onCloseMenu="closeMenu"
    />

    <div
      class="header-mobile-expanded__menu-block header-mobile-expanded__forexlive"
    >
      <a-link
        :to="$env.FL_DOMAIN_URL"
        open-in-new-tab
        class="forexlive-item"
        @click="closeMenu"
      >
        <span class="forexlive-title">Market News</span>
      </a-link>
      <span class="forexlive-item-suffix">by</span>
      <a-icon
        :icon="ICON.FL"
        :width="78"
        :height="25"
        fill="#fff"
        is-button
        id="header-mobile-logo-forexlive"
        aria-label="ForexLive"
        no-hover
        class="forexlive-icon"
      />
    </div>

    <div class="header-mobile-expanded__menu-block">
      <ul>
        <li @click="openNewsLetterModal" class="single-social-btn">
          <a-icon
            :icon="getOurNewsletter.icon"
            :width="15"
            :height="15"
            fill="#fff"
            class="social-icon"
          />
          <p class="social-title">
            {{ getOurNewsletter.name }}
          </p>
        </li>
        <li v-for="social in socialList" :key="social.name">
          <a-link :to="social.link" class="single-social-btn">
            <a-icon
              :icon="social.icon"
              :width="15"
              :height="15"
              fill="#fff"
              class="social-icon"
            />
            <p class="social-title">
              {{ social.name }}
            </p>
          </a-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import AIcon, { ICON } from 'shared/AIcon'
import AHeaderMenuBlock from './AHeaderMenuBlock'
import { MODAL } from 'modals/AModalWrapper'
import AGlobalQuicksearch from 'shared/AGlobalQuicksearch'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { REFS } from 'enums/external-refs'

const BASE_BOTTOM_PADDING = 50

export default {
  name: 'AHeaderMobileExpanded',
  mixins: [hydrationHelpers],
  components: {
    AGlobalQuicksearch,
    AHeaderMenuBlock,
    AIcon
  },
  props: {
    menuList: propValidator([PROP_TYPES.ARRAY]),
    isMenuExpanded: propValidator([PROP_TYPES.BOOLEAN])
  },
  consts: { REFS },
  data() {
    return {
      ICON,
      expandedSectionIndex: null,
      getOurNewsletter: {
        name: 'Subscribe to our News & Services',
        icon: ICON.ENVELOPE
      },
      socialList: [
        {
          name: 'FM ALL News',
          link: this.$env.TELEGRAM_CHANNEL_FM,
          icon: ICON.TELEGRAM
        },
        {
          name: 'FM Crypto',
          link: this.$env.TELEGRAM_CHANNEL_CRYPTO,
          icon: ICON.TELEGRAM
        },
        {
          name: 'Follow us on Twitter',
          link: this.$env.TWITTER_CHANNEL,
          icon: ICON.TWITTER
        },
        {
          name: 'Follow us on Linkedin',
          link: this.$env.LINKEDIN_CHANNEL,
          icon: ICON.LINKEDIN
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      mobileBottomPadding: 'mobileBottomPadding'
    }),
    wrapperStyles() {
      return {
        paddingBottom: `${this.mobileBottomPadding + BASE_BOTTOM_PADDING}px`
      }
    },
    menuItemsForSectionOne() {
      return this.menuList
        .filter(el => el.MobileSection === 1)
        .reduce(
          (arr, current) => {
            const { ChildItems } = current
            if (current.Name.toLowerCase() !== 'education') {
              return [...arr, ...ChildItems]
            }
            return [
              ...arr,
              { Name: current.Name, Link: current.Link, ChildItems }
            ]
          },
          [{ Name: 'All News', Link: 'all-news', ChildItems: null }]
        )
    },
    menuItemsForSectionTwo() {
      return this.menuList.filter(el => el.MobileSection === 2)
    }
  },
  watch: {
    isMenuExpanded: {
      handler(newVal) {
        if (newVal) return this.$_hydrationHelpers_hideScroll()

        this.$_hydrationHelpers_showScroll()
      }
    }
  },
  methods: {
    closeMenu() {
      this.$emit('onCloseMenu')
    },
    openNewsLetterModal() {
      this.$helper.openModal(MODAL.NEWSLETTER_FORM)
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile-expanded__menu-container {
  height: calc(100vh - #{$mobile-header-height});
  width: 100%;
  padding-bottom: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  /deep/ .input__wrapper {
    border: none;
  }
}

.header-mobile-expanded__search-bar-container {
  margin: 10px auto 0;
  width: 80%;
  max-width: 800px;

  @include mobile {
    width: 100%;
    margin: 10px 0 0;
  }
}

.header-mobile-expanded__menu-block {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  border-bottom: 2px solid white;

  &.header-mobile-expanded__forexlive {
    flex-direction: row;
    align-items: center;
  }

  &:last-child {
    border-bottom: none;
  }
}

.forexlive-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.forexlive-icon {
  margin-left: 6px;
}

.forexlive-title {
  color: white;
  transition: color 0.3s;

  &:hover {
    color: $c--gray-dark;
  }
}

.forexlive-item-suffix {
  margin-left: 5px;
}

.social-title {
  font-weight: lighter;
  font-size: 14px;

  transition: color 0.3s;
  color: $c--white;
}

.single-social-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;

  &:hover {
    color: $c--gray-dark;
  }

  &.top {
    margin-top: 10px;
    padding-bottom: 0;
  }
}

.social-icon {
  margin-right: 20px;
}
</style>
