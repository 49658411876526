<template>
  <div class="mobile-menu__wrapper">
    <a-link
      class="mobile-menu__single-parent-item"
      :style="menuParentStyles"
      :to="parent.Link"
      @click="onParentItemClick(parent.Link)"
    >
      {{ parent.Name }}
      <span
        :class="menuArrowClassName"
        @click.prevent.stop="toggleMenu"
        @touchend.stop
      />
    </a-link>
    <collapse-transition>
      <ul v-show="isExpanded" class="mobile-menu__child-items-container">
        <li
          v-for="(child, childIndex) in parent.ChildItems"
          :key="`${child.Name}-${childIndex}`"
          class="mobile-menu__child-item"
        >
          <a-header-menu-block-items
            v-if="isNestedLevelDisplayed(child)"
            :parent="child"
            :nesting-level="nestingLevel + 1"
            @onCloseMenu="onItemClick"
          />
          <a-link
            v-else
            @click="onItemClick"
            class="mobile-menu__single-child-item"
            :to="child.Link"
          >
            {{ child.Name }}
          </a-link>
        </li>
      </ul>
    </collapse-transition>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

const DEFAULT_PARENT_MENU_MAX_WIDTH = 170
const DEFAULT_PARENT_MENU_MAX_WIDTH_OFFSET = 40

export default {
  name: 'AHeaderMenuBlockItems',
  props: {
    parent: propValidator([PROP_TYPES.OBJECT]),
    nestingLevel: propValidator([PROP_TYPES.NUMBER], false, 0)
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    menuArrowClassName() {
      return this.isExpanded
        ? 'parent-item__arrow_down'
        : 'parent-item__arrow_right'
    },
    menuParentStyles() {
      return {
        'max-width':
          DEFAULT_PARENT_MENU_MAX_WIDTH +
          this.nestingLevel * DEFAULT_PARENT_MENU_MAX_WIDTH_OFFSET +
          'px'
      }
    }
  },
  methods: {
    toggleMenu() {
      this.isExpanded = !this.isExpanded
    },
    onParentItemClick(link) {
      if (!link) return

      this.onItemClick()
    },
    onItemClick() {
      this.$emit('onCloseMenu')
    },
    isNestedLevelDisplayed(child) {
      return child.ChildItems && child.ChildItems.length
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu__wrapper {
  .mobile-menu__child-items-container {
    padding: 2px 0 2px 24px;
    max-width: 420px;
  }

  .mobile-menu__child-item {
    cursor: pointer;
  }

  .mobile-menu__single-child-item {
    width: 100%;
    position: relative;
    display: block;
    cursor: pointer;
    padding: 9px 0;
    transition: color 0.3s;

    color: white;

    &:hover {
      color: $c--navy;
    }
  }

  .mobile-menu__single-parent-item {
    font-size: 16px;
    position: relative;
    display: block;
    cursor: pointer;
    padding: 9px 25px 9px 0;
    transition: color 0.3s;

    color: white;

    &:hover {
      color: $c--navy;
    }
  }

  .parent-item__arrow {
    &_right,
    &_down {
      position: absolute;
      top: calc(50% - 2px);
      transform: translateY(-50%);
      right: 0;

      &::after,
      &::after {
        content: '';
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transition: transform 0.3s;
      }
    }

    &_right::after {
      transform: rotate(-45deg);
    }

    &_down::after {
      transform: rotate(45deg);
    }
  }
}
</style>
