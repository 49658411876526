<template>
  <div class="header-mobile-expanded__menu-block">
    <div
      v-for="(parent, parentIndex) in menuItems"
      :key="`${parent.Name}-${parentIndex}`"
      class="mobile-menu__parent-container"
    >
      <a-header-menu-block-items
        v-if="ifHasChildren(parent)"
        :parent="parent"
        @onCloseMenu="collapseHeader"
      />

      <div
        v-else
        class="mobile-menu__parent-without-children"
        @click="collapseHeader"
      >
        <a-link class="mobile-menu__single-parent-item" :to="parent.Link">
          {{ parent.Name }}
        </a-link>
      </div>
    </div>
  </div>
</template>

<script>
import { pathOr } from 'ramda'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AHeaderMenuBlockItems from '@/components/AHeader/AHeaderMobile/AHeaderMobileExpanded/AHeaderMenuBlock/AHeaderMenuBlockItems'

export default {
  name: 'AHeaderMenuBlock',
  components: { AHeaderMenuBlockItems },
  props: {
    menuItems: propValidator([PROP_TYPES.ARRAY])
  },
  methods: {
    ifHasChildren({ ChildItems }) {
      return pathOr(0, ['length'], ChildItems) > 0
    },
    collapseHeader() {
      this.$emit('onCloseMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile-expanded__menu-block {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
  padding: 14px 0;
}

.mobile-menu__single-parent-item {
  font-size: 16px;
  max-width: 170px;
  position: relative;
  display: block;
  cursor: pointer;
  padding: 9px 0;
  transition: color 0.3s;

  color: white;

  &:hover {
    color: $c--gray-dark;
  }
}

.mobile-menu__parent-without-children {
  display: inline-block;
  width: 60%;

  .mobile-menu__single-parent-item {
    width: 100%;
  }
}
</style>
